import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { HELP_LINKS, DISCOVERY_LINKS, SITTER_LINKS, HOUSE_SIT_LINKS } from './constants';
import AlwaysOpenLinks from './components/AlwaysOpenLinks';
import MobileAccordionLinks from './components/MobileAccordionLinks';
import SocialMediaLink from './components/SocialMediaLink';
import StoreButtons from './components/StoreButtons';
import CopyrightFooter from './components/CopyrightFooter';

function Footer({ options }) {
    const { t } = useTranslation();
    const [opened, setOpened] = React.useState(0);

    function updateOpened(index) {
        if (opened === index) {
            setOpened(0);
        } else {
            setOpened(index);
        }
    }

    if (options.hideLinks) {
        return (
            <footer className="mx-6 flex flex-col pb-8 xl:mx-12 xl:items-center">
                <CopyrightFooter />
            </footer>
        );
    }

    return (
        <footer className="mx-6 flex flex-col gap-8 border-0 border-t border-solid border-grey-300 pb-8 xl:mx-12 xl:items-center">
            <section className="flex max-w-[1312px] flex-col gap-8  pt-8 xl:grid xl:grid-cols-5 xl:gap-24">
                <div className="col-span-2 grid grid-cols-2 gap-6 xl:gap-24">
                    <AlwaysOpenLinks
                        title={t('components_footer_column_heading_help')}
                        list={HELP_LINKS}
                    />

                    <AlwaysOpenLinks
                        title={t('components_footer_column_heading_discover_more')}
                        list={DISCOVERY_LINKS}
                    />
                </div>

                <div className="grid gap-6 xl:col-span-2 xl:grid-cols-2 xl:gap-24">
                    <MobileAccordionLinks
                        title={t('components_footer_column_heading_start_searching_for')}
                        list={SITTER_LINKS}
                        isOpen={opened === 1}
                        updateOpen={() => updateOpened(1)}
                    />

                    <MobileAccordionLinks
                        title={t('components_footer_column_heading_why_not_explore')}
                        list={HOUSE_SIT_LINKS}
                        isOpen={opened === 2}
                        updateOpen={() => updateOpened(2)}
                        hasDivider
                    />
                </div>

                <section className="flex flex-col gap-4">
                    <div className="flex gap-4 xl:justify-between">
                        <SocialMediaLink type="twitter" />
                        <SocialMediaLink type="instagram" />
                        <SocialMediaLink type="facebook" />
                        <SocialMediaLink type="youtube" />
                    </div>

                    <span>{t('components_footer_column_store_heading')}</span>

                    <div className="flex gap-2 xl:flex-col">
                        <StoreButtons type="apple" />
                        <StoreButtons type="google" />
                    </div>
                </section>
            </section>

            <CopyrightFooter />
        </footer>
    );
}

Footer.defaultProps = {
    options: {
        hideLinks: false,
    },
};

Footer.propTypes = {
    options: PropTypes.shape({
        hideLinks: PropTypes.bool,
    }),
};

export default Footer;
