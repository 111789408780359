import React from 'react';
import { format } from 'date-fns';

import { TERMS_OF_SERVICE_LINK, PRIVACY_POLICY_LINK } from '../../constants';
import FooterLink from '../FooterLink';

function CopyrightFooter() {
    return (
        <section className="flex w-full justify-center border-0 border-t border-solid border-grey-300 pt-8">
            <div className="flex w-full flex-col gap-6 md:flex-row xl:max-w-[1312px]">
                <span>&#xa9; {format(new Date(), 'yyyy')} TrustedHousesitters.com &#8482;</span>

                <ul className="m-0 flex list-none gap-4 p-0">
                    <FooterLink path={TERMS_OF_SERVICE_LINK.path}>
                        {TERMS_OF_SERVICE_LINK.title}
                    </FooterLink>
                    <FooterLink path={PRIVACY_POLICY_LINK.path}>
                        {PRIVACY_POLICY_LINK.title}
                    </FooterLink>
                </ul>
            </div>
        </section>
    );
}

export default CopyrightFooter;
