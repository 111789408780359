import React from 'react';
import PropTypes from 'prop-types';

import { TwitterIcon, InstagramIcon, YoutubeIcon, FacebookIcon } from '@ths/design-system/icons';

const socialMediaInfo = {
    twitter: {
        icon: TwitterIcon,
        url: 'https://twitter.com/Housesitting',
    },
    instagram: {
        icon: InstagramIcon,
        url: 'https://www.instagram.com/trustedhousesitters/',
    },
    facebook: {
        icon: FacebookIcon,
        url: 'https://www.facebook.com/trustedhousesitters/',
    },
    youtube: {
        icon: YoutubeIcon,
        url: 'https://www.youtube.com/user/trustedhousesitters',
    },
};

function SocialMediaLink({ type }) {
    const { icon: Icon, url } = socialMediaInfo[type];

    return (
        <a href={url} className="text-primary-700">
            <Icon className="h-8 w-8" />
        </a>
    );
}

SocialMediaLink.propTypes = {
    type: PropTypes.oneOf(['twitter', 'instagram', 'facebook', 'youtube']).isRequired,
};

export default SocialMediaLink;
