import React from 'react';
import PropTypes from 'prop-types';

import FooterLink from '../FooterLink';

function AlwaysOpenLinks({ title, list }) {
    return (
        <div className="flex flex-col gap-6">
            <strong className="text-grey-700">{title}</strong>

            <ul className="m-0 flex list-none flex-col gap-4 p-0">
                {list.map((link) => (
                    <FooterLink key={link.path} path={link.path}>
                        {link.title}
                    </FooterLink>
                ))}
            </ul>
        </div>
    );
}

AlwaysOpenLinks.propTypes = {
    title: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            path: PropTypes.string,
        })
    ).isRequired,
};

export default AlwaysOpenLinks;
