import React from 'react';
import PropTypes from 'prop-types';

import { ChevronRightIcon } from '@ths/design-system/icons';
import FooterLink from '../FooterLink';

function MobileAccordionLinks({ title, list, isOpen, updateOpen, hasDivider }) {
    return (
        <div className="flex flex-col gap-6">
            {hasDivider ? (
                <div className="w-full border-0 border-t border-solid border-grey-300 xl:hidden" />
            ) : null}

            <button
                id={`accordion-links-${title}`}
                aria-expanded={isOpen}
                type="button"
                onClick={updateOpen}
                className="flex cursor-pointer items-center justify-between border-0 p-0 xl:hidden"
            >
                <strong className="text-grey-700">{title}</strong>
                <ChevronRightIcon
                    data-open={isOpen}
                    className="h-4 transition-all duration-300 data-[open=true]:rotate-90"
                />
            </button>

            <strong className="hidden text-grey-700 xl:block">{title}</strong>

            <div
                aria-labelledby={`accordion-links-${title}`}
                data-open={isOpen}
                className="-mb-6 grid grid-rows-[0fr] transition-all duration-300 data-[open=true]:mb-0 data-[open=true]:grid-rows-[1fr] xl:mb-0 xl:block"
            >
                <ul className="m-0 flex list-none flex-col gap-4 overflow-hidden p-0">
                    {list.map((link) => (
                        <FooterLink key={link.path} path={link.path}>
                            {link.title}
                        </FooterLink>
                    ))}
                </ul>
            </div>
        </div>
    );
}

MobileAccordionLinks.defaultProps = {
    hasDivider: false,
};

MobileAccordionLinks.propTypes = {
    title: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            path: PropTypes.string,
        })
    ).isRequired,
    isOpen: PropTypes.bool.isRequired,
    updateOpen: PropTypes.func.isRequired,
    hasDivider: PropTypes.bool,
};

export default MobileAccordionLinks;
