/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const TwitterIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 32 32"
        ref={ref}
        {...props}
    >
        <title>{'twitter'}</title>
        <path
            d="M28.86 1H3.14A2.14 2.14 0 0 0 1 3.14v25.72A2.14 2.14 0 0 0 3.14 31h25.72A2.14 2.14 0 0 0 31 28.86V3.14A2.14 2.14 0 0 0 28.86 1Zm-6.47 11.73v.42A9.39 9.39 0 0 1 8 21.06a6.79 6.79 0 0 0 .78 0 6.59 6.59 0 0 0 4.1-1.41 3.31 3.31 0 0 1-3.11-2.25 3.11 3.11 0 0 0 .62.06 3 3 0 0 0 .86-.12 3.3 3.3 0 0 1-2.64-3.23 3.3 3.3 0 0 0 1.49.42 3.33 3.33 0 0 1-1-4.41 9.37 9.37 0 0 0 6.8 3.45 3.84 3.84 0 0 1-.09-.75 3.3 3.3 0 0 1 5.71-2.26 6.53 6.53 0 0 0 2.09-.8 3.35 3.35 0 0 1-1.45 1.83A7.12 7.12 0 0 0 24 11a7.06 7.06 0 0 1-1.61 1.73Z"
            className="cls-1"
        />
    </svg>
);
const ForwardRef = forwardRef(TwitterIcon);
export default ForwardRef;
