import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function FooterLink({ path, children }) {
    if (path.startsWith('http')) {
        return (
            <li>
                <a href={path} className="text-grey-700 no-underline">
                    {children}
                </a>
            </li>
        );
    }

    return (
        <li>
            <Link to={path} className="text-grey-700 no-underline">
                {children}
            </Link>
        </li>
    );
}

FooterLink.defaultProps = {
    children: 'Placeholder text',
};

FooterLink.propTypes = {
    path: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default FooterLink;
