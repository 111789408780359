/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const InstagramIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 32 32"
        ref={ref}
        {...props}
    >
        <title>{'instagram'}</title>
        <path
            d="M16 13.14A2.86 2.86 0 1 0 18.86 16 2.86 2.86 0 0 0 16 13.14Z"
            className="cls-1"
        />
        <path
            d="M22.68 10.94a2.85 2.85 0 0 0-1.62-1.62 4.77 4.77 0 0 0-1.6-.3h-6.92a4.77 4.77 0 0 0-1.6.3 2.85 2.85 0 0 0-1.62 1.62 4.77 4.77 0 0 0-.3 1.6v6.92a4.77 4.77 0 0 0 .3 1.6 2.85 2.85 0 0 0 1.62 1.62 4.77 4.77 0 0 0 1.6.3c.9 0 1.17.05 3.46.05s2.56 0 3.46-.05a4.77 4.77 0 0 0 1.6-.3 2.85 2.85 0 0 0 1.62-1.62 4.77 4.77 0 0 0 .3-1.6c0-.9.05-1.17.05-3.46s0-2.56-.05-3.46a4.77 4.77 0 0 0-.3-1.6ZM16 20.4a4.4 4.4 0 1 1 4.4-4.4 4.4 4.4 0 0 1-4.4 4.4Zm4.58-7.95a1 1 0 1 1 1-1 1 1 0 0 1-1 1Z"
            className="cls-1"
        />
        <path
            d="M29.39 1H2.61A1.61 1.61 0 0 0 1 2.61v26.78A1.61 1.61 0 0 0 2.61 31h26.78A1.61 1.61 0 0 0 31 29.39V2.61A1.61 1.61 0 0 0 29.39 1Zm-4.87 18.53a6.22 6.22 0 0 1-.4 2.08 4.39 4.39 0 0 1-2.51 2.51 6.22 6.22 0 0 1-2.08.4c-.91 0-1.2.05-3.53.05s-2.62 0-3.53-.05a6.22 6.22 0 0 1-2.08-.4 4.39 4.39 0 0 1-2.51-2.51 6.22 6.22 0 0 1-.4-2.08c0-.91-.05-1.2-.05-3.53s0-2.62.05-3.53a6.22 6.22 0 0 1 .4-2.08 4.39 4.39 0 0 1 2.51-2.51 6.22 6.22 0 0 1 2.08-.4c.91 0 1.2-.05 3.53-.05s2.62 0 3.53.05a6.22 6.22 0 0 1 2.08.4 4.39 4.39 0 0 1 2.51 2.51 6.22 6.22 0 0 1 .4 2.08c0 .91.05 1.2.05 3.53s-.01 2.62-.05 3.53Z"
            className="cls-1"
        />
    </svg>
);
const ForwardRef = forwardRef(InstagramIcon);
export default ForwardRef;
