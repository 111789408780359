/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const YoutubeIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 32 32"
        ref={ref}
        {...props}
    >
        <title>{'youtube'}</title>
        <path d="M14.29 18.55 18.78 16l-4.49-2.55v5.1z" className="cls-1" />
        <path
            d="M28.86 1H3.14A2.14 2.14 0 0 0 1 3.14v25.72A2.14 2.14 0 0 0 3.14 31h25.72A2.14 2.14 0 0 0 31 28.86V3.14A2.14 2.14 0 0 0 28.86 1Zm-4.6 19.15a2.16 2.16 0 0 1-1.51 1.53 51 51 0 0 1-6.7.36 51 51 0 0 1-6.7-.36 2.13 2.13 0 0 1-1.51-1.53A22.43 22.43 0 0 1 7.48 16a22.43 22.43 0 0 1 .36-4.15 2.13 2.13 0 0 1 1.51-1.53 51 51 0 0 1 6.7-.36 51 51 0 0 1 6.7.36 2.16 2.16 0 0 1 1.51 1.53 22.43 22.43 0 0 1 .36 4.15 22.43 22.43 0 0 1-.36 4.15Z"
            className="cls-1"
        />
    </svg>
);
const ForwardRef = forwardRef(YoutubeIcon);
export default ForwardRef;
