import React from 'react';
import PropTypes from 'prop-types';

import { track, Events } from 'utils/analytics';
import { SVGComponent as AppStoreBadge } from 'src/shared/assets/app_store_badge.svg';
import { SVGComponent as PlayStoreBadge } from 'src/shared/assets/play_store_badge.svg';

const storeInfo = {
    apple: {
        icon: AppStoreBadge,
        url: 'https://itunes.apple.com/gb/app/trustedhousesitters/id1292606611',
        eventDescription: 'app store download',
    },
    google: {
        icon: PlayStoreBadge,
        url: 'https://play.google.com/store/apps/details?id=com.trustedhousesitters.TrustedHousesitters',
        eventDescription: 'play store download',
    },
};

function StoreButtons({ type }) {
    const { icon: Icon, url, eventDescription } = storeInfo[type];

    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
                track(
                    Events.BUTTONCLICK.create({
                        description: eventDescription,
                    })
                )
            }
        >
            <Icon className="fill-utility-black" />
        </a>
    );
}

StoreButtons.propTypes = {
    type: PropTypes.oneOf(['apple', 'google']).isRequired,
};

export default StoreButtons;
