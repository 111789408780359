import { routes } from 'utils/routes';
import { SITEMAP_PATH } from 'pages/Sitemap/Sitemap.constants';
import { i18n } from 'src/i18n';

export const HELP_LINKS = [
    {
        title: i18n.t('components_footer_column_help_how_it_works'),
        path: '/how-it-works/find-a-sitter/',
    },
    {
        title: i18n.t('components_footer_column_help_pricing'),
        path: '/pricing/',
    },
    {
        title: i18n.t('components_footer_column_help_trust_and_safety'),
        path: '/trust-and-safety/',
    },
    {
        title: i18n.t('components_footer_column_help_help_desk'),
        path: 'https://support.trustedhousesitters.com/',
    },
    {
        title: i18n.t('components_footer_column_help_home_and_contents_plan'),
        path: routes.page.page({
            slug: 'home-and-contents-protection',
        }),
    },
    {
        title: i18n.t('components_footer_column_help_house_sitting_and_border_control'),
        path: routes.page.page({
            slug: 'international-house-sitting-and-immigration',
        }),
    },
    {
        title: i18n.t('components_footer_column_help_reviews'),
        path: routes.page.page({
            slug: 'trustedhousesitters-reviews',
        }),
    },
];

export const DISCOVERY_LINKS = [
    {
        title: i18n.t('components_footer_column_discover_more_about_us'),
        path: '/pages/about/',
    },
    {
        title: i18n.t('components_footer_column_discover_more_community_forum'),
        path: 'https://forum.trustedhousesitters.com/',
    },
    {
        title: i18n.t('components_footer_column_discover_more_sustainability'),
        path: '/pages/sustainability/',
    },
    {
        title: i18n.t('components_footer_column_discover_more_careers'),
        path: '/careers/',
    },
    {
        title: i18n.t('components_footer_column_discover_more_blog'),
        path: '/blog/',
    },
    {
        title: i18n.t('components_footer_column_discover_more_press_room'),
        path: '/pages/press-room/',
    },
    {
        title: i18n.t('components_footer_column_discover_more_affiliates'),
        path: routes.page.page({
            slug: 'affiliates',
        }),
    },
    {
        title: i18n.t('components_footer_column_discover_more_working_together'),
        path: routes.page.page({
            slug: 'working-together',
        }),
    },
];

export const SITTER_LINKS = [
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters'),
        path: routes.search.profiles(),
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_cat_sitters'),
        path: '/cat-sitters/',
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_dog_sitters_near_me'),
        path: '/dog-sitters-near-me/',
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters_in_london'),
        path: routes.search.profiles({
            countrySlug: 'united-kingdom',
            admin1Slug: 'england',
            slug: 'london',
        }),
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters_in_new_york'),
        path: routes.search.profiles({
            countrySlug: 'united-states',
            admin1Slug: 'new-york',
            slug: 'new-york-city',
        }),
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters_in_los_angeles'),
        path: routes.search.profiles({
            countrySlug: 'united-states',
            admin1Slug: 'california',
            slug: 'los-angeles',
        }),
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters_in_sydney'),
        path: routes.search.profiles({
            countrySlug: 'australia',
            admin1Slug: 'new-south-wales',
            slug: 'sydney',
        }),
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters_in_melbourne'),
        path: routes.search.profiles({
            countrySlug: 'australia',
            admin1Slug: 'victoria',
            slug: 'melbourne',
        }),
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters_in_vancouver'),
        path: routes.search.profiles({
            countrySlug: 'canada',
            admin1Slug: 'british-columbia',
            slug: 'vancouver',
        }),
    },
    {
        title: i18n.t('components_footer_column_start_searching_for_pet_sitters_all_locations'),
        path: `/${SITEMAP_PATH}/pet-sitters/`,
    },
];

export const HOUSE_SIT_LINKS = [
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits'),
        path: routes.search.listings(),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_long_term_house_sits'),
        path: routes.page.page({
            slug: 'long-term',
        }),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits_in_london'),
        path: routes.search.listings({
            countrySlug: 'united-kingdom',
            admin1Slug: 'england',
            slug: 'london',
        }),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits_in_new_york'),
        path: routes.search.listings({
            countrySlug: 'united-states',
            admin1Slug: 'new-york',
            slug: 'new-york-city',
        }),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits_in_los_angeles'),
        path: routes.search.listings({
            countrySlug: 'united-states',
            admin1Slug: 'california',
            slug: 'los-angeles',
        }),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits_in_sydney'),
        path: routes.search.listings({
            countrySlug: 'australia',
            admin1Slug: 'new-south-wales',
            slug: 'sydney',
        }),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits_in_melbourne'),
        path: routes.search.listings({
            countrySlug: 'australia',
            admin1Slug: 'victoria',
            slug: 'melbourne',
        }),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits_in_vancouver'),
        path: routes.search.listings({
            countrySlug: 'canada',
            admin1Slug: 'british-columbia',
            slug: 'vancouver',
        }),
    },
    {
        title: i18n.t('components_footer_column_why_not_explore_house_sits_all_locations'),
        path: `/${SITEMAP_PATH}/house-sits/`,
    },
];

export const TERMS_OF_SERVICE_LINK = {
    title: i18n.t('components_footer_copyrigth_terms_of_service'),
    path: '/terms-and-conditions/',
};

export const PRIVACY_POLICY_LINK = {
    title: i18n.t('components_footer_copyrigth_privacy_policy'),
    path: '/privacy-policy/',
};
